import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { __ } from '../../i18n';

export async function setData(medias) {
  // Gets 'medias' array from Item.js and returns an object array containing the right url and type of the medias!
  const data = await medias.nodes.map((media) => {
    const { mimetype } = media;
    let { uri } = media;
    uri = mimetype.includes('image') ? uri.replace('https://d391o0jis58pqh.cloudfront.net/w1280', 'https://s3-us-west-2.amazonaws.com') : uri;
    uri = mimetype.includes('image') ? uri.replace('https://images.classapp.com.br/w1280', 'https://s3-us-west-2.amazonaws.com') : uri;

    return { url: uri, mimetype };
  });
  return data;
}

function urlToPromise(url) {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export async function momentsDownload(medias, fileName, toggleDownloadingState, popupError, app) {
  try {
    toggleDownloadingState(true);
    const data = await setData(medias);
    const zip = new JSZip();
    const imgs = zip.folder(__('Images'));
    const vid = zip.folder(__('Videos'));
    let hasImages = false;
    let hasVideos = false;

    if (app && app.env === 'production') {
      await data.forEach((media) => {
        const { mimetype, url } = media;

        if (mimetype.includes('video')) {
          vid.file(url.replace(/.*\//g, ''), urlToPromise(url), { binary: true });
          hasVideos = true;
        } else {
          imgs.file(url.replace(/.*\//g, ''), urlToPromise(url), { binary: true });
          hasImages = true;
        }
      });
    } else {
      await data.forEach((media) => {
        const { url } = media;
        const url2 = console.info(url.replace('https://', 'http://'));
        imgs.file(url2, urlToPromise(url2), { binary: true });
        hasImages = true;
      });
    }

    if (!hasImages) { await zip.remove(__('Images')); }

    if (!hasVideos) { await zip.remove(__('Videos')); }

    await zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, fileName + '.zip');
    });
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadingState(false);
  }
}

export async function videoDownload(media, fileName, toggleDownloadState, popupError) {
  try {
    toggleDownloadState(true);
    const { uri } = media;
    const zip = new JSZip();

    zip.file(uri.replace(/.*\//g, ''), urlToPromise(uri), { binary: true });

    await zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, fileName + '.zip');
    });
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadState(false);
  }
}


export async function imageDownload(media, fileName, toggleDownloadState, popupError, app) {
  try {
    toggleDownloadState(true);
    const { uri } = media;
    const zip = new JSZip();

    if (app && app.env === 'production') {
      zip.file(uri, urlToPromise(uri), { binary: true });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, fileName + '.zip');
      });
    } else {
      const url = console.info(uri.replace('https://', 'http://'));
      zip.file(url, urlToPromise(url), { binary: true });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, fileName + '.zip');
      });
    }
  } catch (err) {
    console.log(`An error occurred: ${err}`);
    popupError(__('An error has occurred.'));
  } finally {
    toggleDownloadState(false);
  }
}
